<script>
  import Button, { Label } from "@smui/button";
  import Chip, { Set, Text, TrailingIcon } from "@smui/chips";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import sceneImagePickupAndUnload from "~/assets/images/scenePickupAndSort.png";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { initAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    NotificationCategory,
    QrHomeTypes,
    STATUS_HELD_IN_DEPOT,
    inTransitWorkTypes,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { updateCenter } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";
  import DepotSelector from "~/pages/QrHome/DepotSelector.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 荷受け・荷下ろし用のタブ定義 */
  const tabs = {
    荷受け: "荷受け",
    荷下ろし: "荷下ろし",
  };
  /** アクティブな荷受け・荷下ろし用のタブ */
  let activeTab = userContext.inTransitDeliveryList?.length
    ? tabs.荷下ろし
    : tabs.荷受け;

  /**
   * センターIDをキーとしたセンター情報のMap
   * @type {Map<number, import("~/libs/commonTypes").DepotLocation>}
   */
  let centersMap;

  /** 選択された配送センター（「ID/名前」形式）[DepotSelectorの変数をbind] */
  let selectedDepot;

  /** 荷受時の作業内容 @type {number} */
  let selectInTransitWork;

  /** 輸送中荷物の情報 @type {Array<object>} */
  let inTransitDeliveryList = [];

  /**
   * 荷下ろし対象の情報
   * @type {{
   *   transportDestinationId: number,
   *   numberOfBasketCar: number,
   * }}
   */
  let unloadingTargetInfo = {
    transportDestinationId: null,
    numberOfBasketCar: 0,
  };

  /** 荷下ろし登録確認ダイアログ @type {ConfirmDialog} */
  let unloadingConfirmDialog;

  /** 荷下ろしモード @type {boolean} */
  let unloadingMode = false;

  // ページの初期化処理（非同期）
  loadingProgress.wrapAsync(async () => {
    // 荷受けの場合かつ輸送中荷物がある場合に配送センターリストを取得
    if (userContext.inTransitDeliveryList?.length) {
      centersMap = await depotLocations.getCentersMap();
      inTransitDeliveryList = userContext.inTransitDeliveryList;

      // 全ての荷下ろし中チェックを解除
      for (let i = 0; i < inTransitDeliveryList.length; i++) {
        for (
          let j = 0;
          j < inTransitDeliveryList[i].deliveryInfoList.length;
          j++
        ) {
          for (
            let k = 0;
            k <
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
            k++
          ) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c =
              false;
          }
        }
      }
    }
  })();

  /**
   * 指定された輸送元センターから指定された輸送先センターへの輸送中荷物の総個数を返す。
   * @param {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} inTransitDeliveryList
   * @param {number} transportSourceIndex
   * @param {number} transportDestinationIndex
   * @returns {number}
   */
  function getNumberOfInTransitPackagesByIndex(
    inTransitDeliveryList,
    transportSourceIndex,
    transportDestinationIndex,
  ) {
    let count = 0;
    for (const basketCar of inTransitDeliveryList[transportSourceIndex]
      .deliveryInfoList[transportDestinationIndex].basketCarList) {
      for (const trackingNumberAndQuantity of basketCar.v) {
        count += trackingNumberAndQuantity.quantity;
      }
    }
    return count;
  }

  /**
   * 指定された輸送先センターへの輸送中カゴ車の総数を返す。
   * @param {number} transportDestinationId
   * @returns {number}
   */
  function getNumberOfInTransitBasketCars(transportDestinationId) {
    let count = 0;
    for (const inTransitDeliveryInfo of inTransitDeliveryList) {
      for (const deliveryInfo of inTransitDeliveryInfo.deliveryInfoList) {
        if (deliveryInfo.transportDestinationId === transportDestinationId) {
          count += deliveryInfo.basketCarList.length;
        }
      }
    }
    return count;
  }

  /**
   * 荷下ろし対象としてチェック済みのカゴ車と荷物の数を返す。
   * @returns {{
   *   packagesCount: number,
   *   basketCarsCount: number,
   * }}
   */
  function getNumberOfUnloadingPackages() {
    let packagesCount = 0;
    let basketCarsCount = 0;
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            basketCarsCount++;
            for (
              let l = 0;
              l <
              inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].v
                .length;
              l++
            ) {
              packagesCount +=
                inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].v[
                  l
                ].quantity;
            }
          }
        }
      }
    }
    return { packagesCount, basketCarsCount };
  }

  /**
   * 荷下ろし登録を行い、Contextの輸送中荷物情報を更新する
   */
  async function updateStatuses() {
    try {
      const data = await execStatusUpdateApi();
      if (data && data.updateFailed) {
        const updateFailedNumbers = data.updateFailed;
        const outputTrackingNumbers = updateFailedNumbers
          .map((t) => formatTrackingNumber(t))
          .join(", ");
        toast.error(
          $_("errors.unableUnloading", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.ERROR,
          $_("errors.unableUnloading", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
      }
      // Contextの輸送中荷物情報を更新
      updateInTransitDeliveryList();
      toast.info($_("message.updateCompleteUnloading"));
    } catch (e) {
      logger.error(
        "[QrHome] 荷下ろし登録でエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          inTransitDeliveryList: inTransitDeliveryList,
        },
        e,
      );
      if (e instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * 荷下ろしした荷物を「保管中」ステータスに更新する。
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async function execStatusUpdateApi() {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[
              k
            ].v.forEach((trackingNumberAndQuantity) => {
              let event = {
                trackingNumber: trackingNumberAndQuantity.trackingNumber,
                status: STATUS_HELD_IN_DEPOT,
                locationId:
                  inTransitDeliveryList[i].deliveryInfoList[j]
                    .transportDestinationId,
              };
              events.push(event);
            });
          }
        }
      }
    }
    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body);
  }

  /**
   * 荷下ろし登録後の輸送中荷物情報を更新する
   */
  function updateInTransitDeliveryList() {
    // タップされたカゴ車情報を削除
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.splice(
              k,
              1,
            );
            k--;
          }
        }
        if (
          inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length ===
          0
        ) {
          inTransitDeliveryList[i].deliveryInfoList.splice(j, 1);
          j--;
        }
      }
      if (inTransitDeliveryList[i].deliveryInfoList.length === 0) {
        inTransitDeliveryList.splice(i, 1);
        i--;
      }
    }

    // 輸送中荷物がなくなった場合、Contextの輸送中荷物情報を削除
    if (inTransitDeliveryList.length === 0) {
      activeTab = tabs.荷受け;
      inTransitDeliveryList = undefined;
    }

    inTransitDeliveryList = inTransitDeliveryList;
    userContext.inTransitDeliveryList = inTransitDeliveryList;
    userContext.store();
    unloadingMode = false;
  }

  /**
   * 荷下ろし対象が選択されていない場合に荷下ろしモードを解除する。
   */
  function checkUnloadingModeRelease() {
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            return;
          }
        }
      }
    }
    unloadingMode = false;
    unloadingTargetInfo = {
      transportDestinationId: null,
      numberOfBasketCar: 0,
    };
  }

  /**
   * 輸送元センター単位のリストに、荷下ろし対象の輸送先センターが含まれているかを返す。
   * @param {object} deliveryInfoList
   * @returns {boolean}
   */
  function includeTargetDestination(deliveryInfoList) {
    return deliveryInfoList.some(
      (deliveryInfo) =>
        deliveryInfo.transportDestinationId ===
        unloadingTargetInfo.transportDestinationId,
    );
  }

  /**
   * 一括荷受け画面へ遷移する。
   */
  function goToBulkReceivePage() {
    updateCenter.set(selectedDepot);
    pageRouter.moveToBulkReceive();
  }

  /**
   * 荷物のスキャン画面へ遷移する。
   */
  function goToPackageScanPage() {
    initAudioContext();

    updateCenter.set(selectedDepot);

    pageRouter.moveToPickupAndSortWithQrCodeScan();

    appContext.store();
    userContext.store();
  }
</script>

{#if inTransitDeliveryList?.length}
  <div class="workTabs">
    <TabBar tabs={Object.values(tabs)} let:tab bind:active={activeTab}>
      <Tab {tab} ripple={false}>
        <Label>{tab}</Label>
      </Tab>
    </TabBar>
  </div>
{/if}

{#if activeTab === tabs.荷受け}
  <p class="img">
    <img src={sceneImagePickupAndUnload} alt="業務イメージ" />
  </p>
{/if}

{#if activeTab === tabs.荷受け}
  <div class="inputAreaWrapper">
    <div class="inputArea">
      <DepotSelector
        qrHomeType={QrHomeTypes.PICKUP_AND_SORT}
        bind:selectedDepot
      />

      <div class="inputGroup">
        <p class="groupName">作業内容</p>
        <div class="typeArea">
          <FormField>
            <Radio
              bind:group={selectInTransitWork}
              value={inTransitWorkTypes.BULK_RECEIVE}
            />
            <span slot="label" style="font-size: 16px;">一括荷受け</span>
          </FormField>
          <FormField>
            <Radio
              bind:group={selectInTransitWork}
              value={inTransitWorkTypes.INDIVIDUAL_SCAN}
            />
            <span slot="label" style="font-size: 16px;">個別スキャン</span>
          </FormField>
        </div>
      </div>
    </div>
  </div>
{/if}

{#if userContext.inTransitDeliveryList?.length && activeTab === tabs.荷下ろし && centersMap}
  <!-- IF 幹線輸送ドライバーによる荷受け業務時、かつ荷受けした荷物がある場合 -->
  <div class="scanCountInfoArea">
    <div class="totalScanCountArea">
      <p class="totalScanCount">
        {#if !unloadingMode}
          輸送中荷物の総数 {userContext.getNumberOfInTransitPackages()}個
        {:else}
          {centersMap?.get(unloadingTargetInfo.transportDestinationId)?.name ??
            "【取得エラー】"}
          行のカゴ車 {unloadingTargetInfo.numberOfBasketCar}台
        {/if}
      </p>
    </div>

    <div class="tranceportSourceUnitArea">
      {#each inTransitDeliveryList as transportSourceInfo, i}
        {#if !unloadingMode || (unloadingMode && includeTargetDestination(transportSourceInfo.deliveryInfoList))}
          <div class="tranceportSourceUnit">
            <p class="tranceportSourceName">
              <strong
                >{centersMap?.get(transportSourceInfo.transportSourceId)
                  ?.name ?? "【取得エラー】"}</strong
              > 発
            </p>
            <div class="tranceportDestinationUnitArea">
              {#each transportSourceInfo.deliveryInfoList as transportDestinationInfo, j}
                {#if !unloadingMode || (unloadingMode && transportDestinationInfo.transportDestinationId === unloadingTargetInfo.transportDestinationId)}
                  <p class="tranceportDestinationName">
                    <strong
                      >{centersMap?.get(
                        transportDestinationInfo.transportDestinationId,
                      )?.name ?? "【取得エラー】"}</strong
                    >
                    行（カゴ車{transportDestinationInfo.basketCarList
                      .length}台／荷物{getNumberOfInTransitPackagesByIndex(
                      inTransitDeliveryList,
                      i,
                      j,
                    )}個）
                  </p>
                  <Set
                    chips={transportDestinationInfo.basketCarList}
                    let:chip
                    nonInteractive
                  >
                    <Chip
                      {chip}
                      shouldRemoveOnTrailingIconClick={false}
                      style={chip.c
                        ? "background-color: #018786; color: white;"
                        : ""}
                      on:click={(event) => {
                        event.stopPropagation();

                        chip.c = !chip.c;
                        inTransitDeliveryList = inTransitDeliveryList;
                        if (chip.c && !unloadingMode) {
                          // 荷下ろしモードに切り替え
                          unloadingTargetInfo.transportDestinationId =
                            transportDestinationInfo.transportDestinationId;
                          unloadingTargetInfo.numberOfBasketCar =
                            getNumberOfInTransitBasketCars(
                              transportDestinationInfo.transportDestinationId,
                            );
                          unloadingMode = true;
                        } else if (!chip.c && unloadingMode) {
                          // 荷下ろしモードを解除判定
                          checkUnloadingModeRelease();
                        }
                      }}
                    >
                      <Text
                        >{chip.v.reduce(function (
                          sum,
                          TrackingNumberAndQuantity,
                        ) {
                          return sum + TrackingNumberAndQuantity.quantity;
                        }, 0)}個</Text
                      >
                      <TrailingIcon>
                        <span
                          class="material-icons md-18"
                          class:selected={chip.c}
                        >
                          check_circle
                        </span>
                      </TrailingIcon>
                    </Chip>
                  </Set>
                {/if}
              {/each}
            </div>
          </div>
        {/if}
      {/each}
    </div>

    <div class="scanCountInfoNoteArea">
      <p class="scanCountInfoNote">
        {#if !unloadingMode}
          輸送先で荷物を降ろす際に、対象のカゴ車の
          <span class="material-icons md-18 inlineIcon"> check_circle </span>
          マークをタップしてください。
        {:else}
          全てのカゴ車の荷下ろしを終えたら、『荷下ろし済みとして登録する』ボタンをタップしてください。
        {/if}
      </p>
    </div>
  </div>
{/if}

<Label>
  <Button
    color="secondary"
    variant="unelevated"
    style="width: 300px; min-height: 50px; margin: 40px auto 10px;"
    on:click={() => {
      if (unloadingMode) {
        unloadingConfirmDialog.openDialog();
      } else if (selectInTransitWork === inTransitWorkTypes.BULK_RECEIVE) {
        goToBulkReceivePage();
      } else {
        goToPackageScanPage();
      }
    }}
    disabled={(activeTab === tabs.荷受け &&
      (!selectedDepot || selectInTransitWork === undefined)) ||
      (activeTab === tabs.荷下ろし && !unloadingMode)}
  >
    {#if activeTab === tabs.荷下ろし}
      荷下ろし済みとして登録する
    {:else if selectInTransitWork === inTransitWorkTypes.BULK_RECEIVE}
      一括荷受けを開始する
    {:else}
      荷物のスキャンを開始する
    {/if}
  </Button>
</Label>

<div class="subContentsWrapper">
  <!-- 荷下ろし登録確認ダイアログ -->
  <ConfirmDialog
    bind:this={unloadingConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CANCEL_CLOSE}
    onDialogClosedHandler={async (event) => {
      if (event.detail.action === "ok") {
        await updateStatuses();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content">
      {@const unloadingCount = getNumberOfUnloadingPackages()}
      カゴ車{unloadingCount.basketCarsCount}台（荷物{unloadingCount.packagesCount}個）を
      {centersMap
        .get(unloadingTargetInfo.transportDestinationId)
        ?.name?.concat?.("に") ?? ""}荷下ろし済みとして登録します。
      {#if unloadingCount.basketCarsCount < unloadingTargetInfo.numberOfBasketCar}
        <div class="unloadingOmissionNote">
          <p>
            {centersMap.get(unloadingTargetInfo.transportDestinationId)?.name ??
              "【取得エラー】"}行で未チェックのカゴ車が{unloadingTargetInfo.numberOfBasketCar -
              unloadingCount.basketCarsCount}台あります。登録を続行しますか？
          </p>
        </div>
      {/if}
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .workTabs {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }

  .typeArea {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .scanCountInfoArea {
    width: calc(100% - 40px);
    max-height: 25vh;
    margin-top: 12px;

    .totalScanCountArea {
      display: flex;
      width: 100%;
      // border-bottom: solid 1px #444444;
      padding-bottom: 6px;

      .totalScanCount {
        margin-left: 5px;
        font-size: 15px;
        font-weight: bold;
      }
    }

    .tranceportSourceUnitArea {
      max-height: calc(100% - 40px);
      padding: 8px 0 0 5px;
      overflow-y: auto;
      background-color: white;
      border: solid 0.5px #c7c7c7;

      .tranceportSourceUnit {
        margin-bottom: 12px;

        .tranceportSourceName {
          margin-left: 5px;
          font-size: 14px;
        }

        .tranceportDestinationUnitArea {
          margin: 5px 0 0 5px;
          padding: 3px 0 0 2px;
          border-left: solid 5px #c9c7c7;

          .tranceportDestinationName {
            margin-left: 5px;
            margin-bottom: -3px;
            font-size: 14px;
          }

          .selected {
            color: white;
          }
        }
      }
    }

    .scanCountInfoNoteArea {
      margin: 5px 0 0 10px;

      .scanCountInfoNote {
        font-size: 14px;
        line-height: 1.2;

        .inlineIcon {
          vertical-align: middle;
        }
      }
    }
  }

  .unloadingOmissionNote {
    margin-top: 5px;
    padding: 4px 8px;
    background-color: #ffe8e8;
    border-radius: 4px;
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
  }
</style>
